import Vue from "vue";
import Router from "vue-router";

const Video = () => import("@/pages/Video");
const Categories = () => import("@/pages/Categories");
const Download = () => import("@/pages/Download");
const Page404 = () => import("@/pages/404");
const App = () => import("@/App");
const Logs = () => import("@/pages/Logs");
const Provisioning = () => import ("@/pages/Provisioning");
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Video",
      component: Video, 
      beforeEnter: (to, from, next) => checkProvisioned(next)
    },
    {
      path: "/download/:session/:hash",
      name: "Download",
      component: Download,
      props: true
    },
    {
      path: "/categories",
      name: "Categories",
      component: Categories,
      beforeEnter: (to, from, next) => checkProvisioned(next)
    },
    {
      path: '/app',
      name: 'App',
      component: App,
    },
    {
      path: '/logs',
      name: 'Logs',
      component: Logs,
    },
    {
      path: "/provisioning",
      name: "Provisioning",
      component: Provisioning
    },
    {
      path: "*",
      name: "404",
      component: Page404
    },
  ],
  localized: true // TODO: what is this for ?!?...
});
function checkProvisioned(next /*: NavigationGuardNext<Vue>*/ ) /*: any */ {
  const provisioningEnabled = process.env.VUE_APP_PROVISIONING_ENABLED === 'true';
  const provisioningInformation = localStorage.getItem("DW-provisioned");
  if (provisioningEnabled && !provisioningInformation) {
    return next({ name: "Provisioning" });
  }
  next();
}
