import Vue from "vue";
import Root from "./Root.vue";
import store from "./store";
import router from "./router";
import VueI18n from "vue-i18n";
import { languages, defaultLocale } from '@/i18n';
// import logSend from "./logSend";

// Vue.use(logSend);
Vue.use(VueI18n);

import "@/assets/css/reset.css";
import "@/assets/css/utils.css";

Vue.config.productionTip = false;

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    Vue,
    dsn:
      "https://db33c53fa9c343ff9b4a83a2a6f8da9c@o155972.ingest.sentry.io/5728002",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

const messages = Object.assign(languages);

var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages
});

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(Root),
}).$mount("#app");

if (process.env.NODE_ENV === "production") {
  document.addEventListener('contextmenu', event => {
    event.preventDefault();
  });
}
